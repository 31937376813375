import React from 'react'
import styled from 'styled-components'
import { contractAddresses } from '../../../farm/lib/constants'
import { getEthChainInfo } from '../../../utils/getEthChainInfo'

const { ethscanType, chainId } = getEthChainInfo()

const contractAddressesTemp = contractAddresses as { [index: string]: any }

const Nav: React.FC = () => {
  return (
    <StyledNav>

      <StyledLink
        target="_blank"
        href={`https://snowtrace.io/token/0x46EcC1629690986b507A8F410a3eB346F04632E9`}
      >
        $NFTART on Snowtrace
      </StyledLink>

      <StyledLink
        target="_blank"
        href={`https://snowtrace.io/address/0xc0e8Ef58263d62B090b6d4E8C5Ae576b97de3eb3`}

      >
        Staking pool contract
      </StyledLink>
    </StyledNav>
  )
}

const StyledNav = styled.nav`
  align-items: center;
  display: flex;
`

const StyledLink = styled.a`
  color: black;
  font-weight: 400;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  &:hover {
    color: white;
    text-decoration: underline;
  }
  img {
    height: 19px;
  }
`

export default Nav
