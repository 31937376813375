/*
1 Mainnet
3 Ropsten
4 Rinkeby
5 Goerli
42 Kovan
56 Binance Smart Chain Mainnet
97 Binance Smart Chain Testnet
100 xDai
137 Matic
1287 Moonbase Testnet
80001 Matic Testnet
43113 Avalanche Testnet
43114 Avalanche Mainnet
*/

export const getEthChainInfo = () => {
    let chainId: number = 43114;
    let rpcUrl: string = 'https://rpc.ankr.com/avalanche';
    let ethscanType: string = 'testnet.';
    const href = window.location.href;
    if (/\/\/staking.enter.art/.test(href)) {
         chainId = 43114;
         rpcUrl = 'https://rpc.ankr.com/avalanche';
         ethscanType = '';
    }
    return {
        chainId,
        rpcUrl,
        ethscanType
    }
};
