import React, { CSSProperties } from 'react';

interface DataCardProps {
  title: string;
  data: string;
}

export const DataCard: React.FC<DataCardProps> = ({ title, data }) => {
  let formattedData = data;

  if (title === 'Tokens in the pool' || title === 'Total PancakeSwap V2 LP supply') {
    formattedData = (parseFloat(data) / 1000000000000000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else if (title === 'BNB in the farm') {
    formattedData = (parseFloat(data) / 1000000000000000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  } else if (title === 'Trillion NFTART in the pool') {
    formattedData = (parseFloat(data) / 10000000000000).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  } else if (title === 'TVL in the farm in USD') {
    formattedData = (parseFloat(data)).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  }

  return (
    <div style={styles.card}>
      <h3>{title}</h3>
      <p>{formattedData}</p>
    </div>
  );
};


const styles: { [key: string]: CSSProperties } = {
  card: {
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '1rem',
    marginBottom: '1rem',
    minWidth: '250px',
    minHeight: '100px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    border: '2px solid black',
  },
  title: {
    margin: 0,
    marginBottom: '0.5rem',
  },
  data: {
    margin: 0,
    fontSize: '1.5rem',
  },
};
