// 42 - Testnet
// 4 - Rinkeby
// 1 - Mainnet

/*
1 Mainnet
3 Ropsten
4 Rinkeby
5 Goerli
42 Kovan
56 Binance Smart Chain Mainnet
97 Binance Smart Chain Testnet
100 xDai
137 Matic
1287 Moonbase Testnet
80001 Matic Testnet
43113 Avalanche Testnet
43114 Avalanche Mainnet
*/

export const contractAddresses = {
  erc20: {
    43114: '0x46EcC1629690986b507A8F410a3eB346F04632E9',
  },
  erc20v2: {
    43114: '0x46EcC1629690986b507A8F410a3eB346F04632E9',
  },
  farm: {
    43114: '0xc0e8Ef58263d62B090b6d4E8C5Ae576b97de3eb3',
  },
  weth: {
    43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7',
  },
}

export const supportedPools = [
  //{
    //id: 'NFTART',
    //version: 'LP Farm',
    //name: 'NFTART',
    //pid: 0,
    //lpAddresses: {
      //56: '0x2b7fC60FD13F32fEd8730113A14e3468d2f17CdC',
    //},
    //tokenAddresses: { ...contractAddresses.erc20 },
    //symbol: 'NFTART-BNB PCSV2 LP',
    //tokenSymbol: 'NFTART LP',
    //icon: '',
    //pool: '100%',
  //},
  {
    id: 'NFTARTPOOL',
    version: 'Pool',
    name: 'NFTART',
    pid: 0,
    lpAddresses: {
      43114: '0x46EcC1629690986b507A8F410a3eB346F04632E9',
  //    97: '0xB61DF995ed3FEA6bB2d51e7A9C2408e8C269A3D6',
  //    56: '0x2b7fC60FD13F32fEd8730113A14e3468d2f17CdC',
  },
    tokenAddresses: { ...contractAddresses.erc20 },
    symbol: 'NFTART',
    tokenSymbol: 'NFTART',
    icon: '',
    pool: '100%',
  }

]
