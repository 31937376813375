import React, { useMemo, useEffect } from 'react'
import styled from 'styled-components'

import { useParams } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import { provider } from 'web3-core'

import Button from '../../components/Button'
import PageHeader from '../../components/PageHeader'
import Spacer from '../../components/Spacer'

import useYam from '../../hooks/useYam'
import useFarm from '../../hooks/useFarm'
import useRedeem from '../../hooks/useRedeem'
import { getContract } from '../../utils/erc20'
import { getFarmContract } from '../../farm/utils'

import Harvest from './components/Harvest'
import Stake from './components/Stake'

import { ApiDataModalsMini } from '../../components/stats/ApiDataModalsMini';

const Farm: React.FC = () => {
  const { farmId } = useParams<{ farmId: string }>()

  const {
    pid,
    lpToken,
    lpTokenAddress,
    tokenAddress,
    earnToken,
    name,
    icon,
    version,
  } = useFarm(farmId) || {
    pid: 0,
    lpToken: '',
    lpTokenAddress: '',
    tokenAddress: '',
    earnToken: '',
    name: '',
    icon: '',
    version: '',
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const yam = useYam()
  const { ethereum } = useWallet()

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  const { onRedeem } = useRedeem(getFarmContract(yam))

  const lpTokenName = useMemo(() => {
    return lpToken.toUpperCase()
  }, [lpToken])

  const earnTokenName = useMemo(() => {
    return earnToken.toUpperCase()
  }, [earnToken])

  return (
    <>
    <PageHeader
subtitle={`Deposit $NFTART tokens to earn $NFTART`}
title={`${name} ${version}`}
/>
          <ApiDataModalsMini />
      <StyledFarm>
        <StyledCardsWrapper>
          <StyledCardWrapper>
            <Harvest pid={pid} />
          </StyledCardWrapper>
          <Spacer />
          <StyledCardWrapper>
            <Stake
              lpContract={lpContract}
              pid={pid}
              tokenName={lpToken.toUpperCase()}
            />
          </StyledCardWrapper>
        </StyledCardsWrapper>
        <Spacer size="lg" />
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
          }}
        >
          <Button
            text="Buy $NFTART tokens on Trader Joe"
            backgroundColor="rgb(31, 199, 212)"
            size="xs"
            href={`https://traderjoexyz.com/avalanche/trade?outputCurrency=0x46EcC1629690986b507A8F410a3eB346F04632E9`}
          />
        </div>
      </StyledFarm>
    </>
  )
}

const StyledFarm = styled.div`
  margin-top: -70px;
  align-items: center;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const StyledCardsWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
    flex-flow: column nowrap;
    align-items: center;
  }
`

const StyledCardWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 80%;
  }
`

const StyledInfo = styled.h3`
  color: '#17054b';
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  padding: 0;
  text-align: center;
`

export default Farm
